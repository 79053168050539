import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Kiji from "@components/kiji-link";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <section className='honbun'>
      <p><strong parentName="p">{`補足：`}</strong></p>
      <p>{`厳密には、リミッターにかかった瞬間だけはちょっとだけ抑揚が死にますが、瞬間的にかかるぐらいなら全く問題ありません。`}<br parentName="p"></br>{`
`}{`目安としては、リミッターでの圧縮が最大6dBぐらいまでなら、瞬間的に圧縮されても大丈夫です。`}</p>
      <p><strong parentName="p">{`補足２：`}</strong></p>
      <p>{`ラウドネスが数値上はリファレンスと同じになっても、なぜかリファレンスの方が大きく聞こえることがあります。それは、この後に行うEQの調整がまだだからです。EQによって、数値は変わりませんが、聴覚上の大きさは結構変わります（人の耳の特性によるものです）。`}</p>
      <br />
      <p><strong parentName="p">{`補足３：`}</strong></p>
      <p>{`スタジオワンの「Channel Strip」のコンプには、レシオもリリースもついていないので、マスターコンプとしては使用できません。`}<br parentName="p"></br>{`
`}{`代わりに、スタジオワンに付属のFat Channelというプラグインのコンプをお勧めします。`}</p>
      <p>{`（スタジオワンに付属の「Compressor」だと、adaptive releaseなど副作用のある機能が最初からオンになっていて、うまく音圧が上がりません。また、設定できる項目が複雑すぎてMIXを学ぶには適していません）`}</p>
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      